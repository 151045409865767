.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darkened background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust as needed */
}

.overlay__content {
  width: 40rem;
  background-color: white;
  padding: 2rem;
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  position: relative;
}
